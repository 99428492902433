:root {
  --primary: #3855f5;
  --secondary: #74788d;
  --soft-secondary: #e1e1e1;
  --success: #34c38f;
  --info: #50a5f1;
  --warning: #f1b44c;
  --danger: #f46a6a;
  --light: #eff2f7;
  --dark: #343a40;
  --white: #ffffff;
  --bs-custom-white: #ffffff;
  --light-pink: #fff6f6;
  --hover-color: #effbfe;
  --main-button-color: #513dca;
  --main-button-hover-color: #614fc5;
  --selected-menu-item-color: #e7e4fa;
  --main-border-color: #ced4da;
  --header-height: 65px;
  --left-menu-width: 65px;
  --bs-form-check-input-border: #ced4da;
  --bs-body-font-size: 0.8125rem;
  --bs-modal-zindex: 999;
  --bs-modal-header-padding-override: 0.5rem 1rem;
}

@font-face {
  font-family: "AvenirNextLTPro";
  src: url("assets/fonts/AvenirNextLTPro-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  background: radial-gradient(
    circle,
    rgba(234, 252, 255, 1) 0%,
    rgba(240, 238, 253, 1) 67%
  );
}

@import "~@ng-select/ng-select/themes/default.theme.css";

body.dark {
  color: #fff !important;
  background: #27272b !important;
}

.page-title-box {
  padding-bottom: 1rem !important;
}

.modal {
  z-index: 999 !important;
}

.modal-backdrop {
  z-index: 998 !important;
}

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

.modal-header {
  padding: var(--bs-modal-header-padding-override) !important;
}

.mdl-title {
  letter-spacing: 0.34px;
  color: #495057 !important;
  text-transform: uppercase;
  opacity: 1;
  font-size: 18px;
  font-family: "AvenirNextLTPro";
  font-weight: 600;
}

.mdl-head {
  padding-left: 10px;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.bg-white {
  background-color: #fff !important;
}

.bg-gray {
  background-color: #f8f9fa !important;
}

.no-border {
  border: none !important;
}

.fs-tiny {
  font-size: 0.6rem;
}

.primary-button {
  background-color: var(--main-button-color) !important;
  border-color: var(--main-button-color) !important;
  color: white;

  &.small {
    padding: 0.3rem 1rem 0.3rem 1rem;
    font-size: 0.7rem;
  }

  &:hover {
    background-color: var(--main-button-hover-color) !important;
    color: white;
  }
}

.warning-button {
  background-color: #fee3d4 !important;
  border-color: #fee3d4 !important;
  padding: 0.2rem 0.4rem;
  height: 27px;
  font-size: 0.7rem;
  color: #c5622a;

  &:hover {
    background-color: #c5622a !important;
    color: #fff;
  }
}

button.secondary-button {
  background-color: white !important;
  border-color: var(--main-button-) !important;
  color: var(--main-button-color);
  padding: 0.47rem 0.75rem;

  &.small {
    padding: 0.2rem 0.6rem 0.3rem 0.2rem;
    height: 27px;
    font-size: 0.7rem;

    > i {
      font-size: 0.7rem !important;
    }

    & span {
      font-size: 0.7rem;
      vertical-align: middle;
    }
  }

  &.large {
    padding: 0.5rem 1rem;

    & span {
      font-size: 1rem;
      vertical-align: middle;
    }

    > i {
      vertical-align: middle;
      font-size: 1rem !important;
      margin: auto 8px;
    }
  }

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    color: #000000 !important;
    border-color: var(--main-border-color) !important;
    opacity: 0.3;
  }

  > i {
    vertical-align: text-bottom;
    font-size: 0.8rem !important;
    margin: auto 4px;
  }

  & span {
    font-size: 0.8rem;
    vertical-align: middle;
  }

  &.xsmall {
    padding: 0.1rem 0.4rem 0.3rem 0.1rem;

    > i {
      font-size: 0.75rem !important;
      margin-right: 0.2rem;
      vertical-align: middle;
    }

    & span {
      font-size: 0.7rem;
    }
  }
}

.tertiary-button {
  background-color: #f4f3fa !important;
  border-color: #f4f3fa !important;
  color: var(--main-button-color);
  padding: 0.3rem 1.2rem;
  border-radius: 16px;
  font-weight: 600;

  &:hover {
    background-color: var(--selected-menu-item-color) !important;
  }

  &.square {
    padding: 2px 7px;
    border-radius: 4px;
    font-size: 1rem;
  }

  &.small {
    padding: 0.2rem 0.8rem 0.3rem 0.8rem;
    height: 27px;
    font-size: 0.7rem;

    > i {
      font-size: 0.7rem !important;
    }

    & span {
      font-size: 0.7rem;
      vertical-align: middle;
    }
  }
}

.add-new-btn {
  padding: 3px 11px !important;
  border-radius: 4px;
  color: #0520b6 !important;
  letter-spacing: 0.14px;
  background-color: #e8ecfc !important;
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.danger {
  background-color: #fef0f0 !important;
  color: #eb5858;
}

.primary-link {
  color: var(--main-button-color) !important;

  &:hover {
    text-decoration: underline;
  }
}

i.footer-insert-clear-button {
  width: 30px;
  height: 30px;
  padding: 8px 9px;
  border-radius: 5px;
  font-size: 0.9rem;
  background: #f0f1f3;
  color: #64676f;

  &:hover {
    background: #fef0f0;
    color: #eb5858;
  }
}

i.footer-insert-add-button {
  width: 30px;
  height: 30px;
  padding: 8px 9px;
  border-radius: 5px;
  font-size: 0.9rem;
  background: var(--selected-menu-item-color);
  color: var(--main-button-color);

  &:hover {
    background-color: var(--selected-menu-item-color) !important;
  }
}

i.delete-item-button {
  width: 20px;
  height: 20px;
  background: #f0f1f3;
  color: #64676f;
  padding: 2px 3px;
  border-radius: 3px;
  font-size: 1rem;

  &:hover {
    background: #fef0f0;
    color: #eb5858;
  }
}

input.form-control {
  display: block;
  width: 100%;
  padding: 0.37rem 0.75rem;
  font-size: var(--bs-body-font-size);
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--main-border-color);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #343a40;
    background-color: #fff;
    border-color: rgb(73, 80, 87, 0.2);
    outline: 0;
    box-shadow: none;
  }

  &.hide-error-image {
    background-image: none !important;
  }
}

.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-daygrid-event.fc-daygrid-dot-event:hover {
  background: #556ee6;
}

.fc-event-selected,
.fc-event:focus {
  box-shadow: none;
}

.fc-event,
.fc-event-dot {
  background-color: #513dca !important;
}

select.form-select {
  display: block;
  width: 100%;
  padding: 0.37rem 1.75rem 0.37rem 0.75rem;
  font-size: var(--bs-body-font-size);
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid var(--main-border-color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;

  &:focus {
    border-color: rgb(73, 80, 87, 0.2);
    outline: 0;
    box-shadow: 0 0 0 0.15rem #556ee640;
  }
}

textarea.form-control {
  border: 1px solid var(--main-border-color);
  border-radius: 0.25rem;
  background-color: #fbfbfb !important;

  &:focus {
    border-color: rgb(73, 80, 87, 0.2);
    outline: 0;
    box-shadow: 0 0 0 0.15rem #556ee640;
  }
}

textarea {
  resize: none;
}

input.file-upload.ng-invalid.ng-touched {
  color: #f46a6a;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border-color: #f46a6a;
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

ngx-intl-tel-input > div.iti {
  width: 100%;
}

.country-dropdown {
  font-size: var(--bs-body-font-size) !important;
}

#country-search-box {
  padding: 5px 8px;

  &:focus-visible {
    outline: none !important;
  }
}

.table-micro-column,
.w-1rem {
  width: 1rem;
  min-width: 1rem !important;
}

.table-action-column,
.table-mini-column,
.w-5rem {
  width: 5rem !important;
  min-width: 5rem !important;
}

.meds-table-action-col {
  width: 200px;
  text-align: end;
}

.small-table-column,
.w-10rem {
  width: 10rem !important;
  min-width: 10rem !important;
}

.w-12rem {
  width: 12rem !important;
  min-width: 12rem !important;
}

.w-3rem {
  width: 3rem !important;
  min-width: 3rem !important;
}

.w-10 {
  width: 10% !important;
}

.w-16 {
  width: 16% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.relative-zindx-2 {
  position: relative;
  z-index: 2;
}

.left-bordered-container {
  padding-left: 0.8rem;
  border-left: 1px solid #e0e3e7;
}

.prior-auth-info-panel .schedule-summary-container .scheduled {
  .col-6 span,
  .col-6 p {
    font-size: 1rem;
  }
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28116, 120, 141, 0.55%29'/%3e%3c/svg%3e") !important;
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
  border: 1px solid var(--main-border-color);
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.btn-close {
  font-size: 1.3rem !important;
}

.btn:disabled {
  color: white !important;
}

.tertiary-button:disabled {
  color: var(--main-button-hover-color) !important;
}

#list-search-box {
  padding-left: 2rem;
  border-radius: 20px;
}

#list-search-icon {
  font-size: 0.75rem;
  top: 18px;
}

span.list-status-column {
  border-radius: 5px;
  padding: 5px 6px;
  background: rgba(85, 110, 230, 0.18);
  color: #556ee6;
  font-size: 0.75rem;
}

span.list-status-column-grey {
  border-radius: 5px;
  padding: 5px 6px;
  color: #74788d;
  background-color: rgba(116, 120, 141, 0.18);
  font-size: 0.75rem;
}

////=================primng table sort icon
sortalticon,
sortamountupalticon,
sortamountdownicon {
  svg {
    display: none !important;
  }
}

sortalticon:before {
  display: none !important;
}

sortamountupalticon:before {
  content: "\e9a2";
}

sortamountdownicon:before {
  content: "\e9a0";
}

sortalticon:before,
sortamountupalticon:before,
sortamountdownicon:before {
  font-size: 0.8rem;
  font-family: "primeicons";
}

//============primeng table styling
.p-component {
  font-family: inherit;
  font-size: 0.8rem;
}

.p-inputnumber {
  width: 100%;
}

.p-inputtext:enabled:hover,
.p-inputtext:enabled:focus {
  border-color: var(--main-border-color) !important;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #343a40;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.4rem;
}

.p-datatable .p-datatable-thead > tr > th {
  color: #495057;
  border-color: #eff2f7;
  background-color: #eff2f7;
  padding: 0.7rem 0.4rem;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.p-paginator .p-dropdown {
  height: 2.3rem !important;
}

.filter-container {
  width: 200px;
}

.health-exam-table {
  font-family: inherit;
  font-size: 0.8rem;

  &.small {
    td {
      padding: 0.2rem;
    }

    thead tr {
      th {
        padding: 0.2rem;
      }
    }
  }

  tbody {
    tr {
      background: #ffffff;
      color: #495057;
      transition: box-shadow 0.2s;

      td {
        padding: 0.7688rem 0.4rem;
        text-align: left;
        border: 1px solid #dee2e6;
        background-color: #fff;
        border-width: 0 0 1px 0;
      }

      &.insert-row td {
        padding: 0.431rem !important;
      }
    }
  }

  thead tr {
    th {
      text-align: left;
      padding: 0.7rem 0.4rem;
      border: 1px solid #dee2e6;
      border-width: 0 0 1px 0;
      font-weight: 700;
      color: #343a40;
      background: #f8f9fa;
      transition: box-shadow 0.2s;
    }
  }
}

.p-datatable .p-datatable-tbody > tr:hover {
  background: #e9ecef;
}

.p-datatable .p-datatable-header {
  background: #fff;
  color: #343a40;
  border: none;
  padding: 0 0.2rem 1rem 0.2rem;
  font-weight: 700;
}

//=============prime date time control
.p-datepicker .p-timepicker {
  padding: 0.5rem !important;
}

.p-datepicker .p-timepicker button {
  width: 1rem !important;
  height: 1rem !important;
}

.p-datepicker .p-timepicker span {
  font-size: 0.8rem !important;
}

.p-datepicker .p-datepicker-header {
  padding: 0 !important;
  border-bottom: 0px !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 0.2rem !important;
}

.p-link {
  font-size: 0.8rem !important;
}

.p-datepicker table {
  font-size: 0.8rem;
}

.p-datepicker table td {
  padding: 0.1rem;
}

.p-datepicker table td > span {
  width: 2rem;
  height: 2rem;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
  border-radius: 4px;
}

.p-datepicker table td > span.p-highlight {
  background: #4338ca;
  color: #eef2ff;
}

.p-datepicker.p-datepicker-header.p-datepicker-prev:hover,
.p-datepicker.p-datepicker-header.p-datepicker-next:hover {
  background-color: #e9ecef !important;
  color: #495057 !important;
  border-radius: 4px !important;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  background: #4338ca !important;
  color: #eef2ff !important;
}

.p-datepicker.p-monthpicker.p-monthpicker-month.p-highlight {
  background: #4338ca !important;
  color: #eef2ff !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: -8px !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  border-radius: 4px !important;
}

.p-inputtext {
  padding: 0.304rem 0.75rem;
}

.last-time-take.p-inputtext {
  padding: 0.37rem 0.75rem;
}

.p-inputtext:enabled:focus {
  color: #343a40;
  background-color: #fff;
  border-color: rgba(73, 80, 87, 0.2);
  outline: 0;
  box-shadow: none;
}

//===========================================================
.pac-container {
  //this is a fix for google autocomplete not showing up
  z-index: 10000 !important;
}

.form-check-default,
.form-check-left,
.form-check-right,
.form-check {
  & .form-check-input:checked {
    background-color: var(--main-button-color);
    border-color: var(--main-button-color);
  }
}

.wide-modal-dialog {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

.wide-modal-dialog .modal-content {
  width: 100%;
  height: 100%;
}

.modal-fixed-height {
  height: calc(100vh - 10rem);
}

.drawer-style-modal {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

.drawer-style-modal .modal-content {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  position: fixed;
  right: 0;
  height: 100%;
  border-radius: 0;
}

.separator {
  border-left: 2px solid #d2d2d2;
  height: 30px;
  width: 1px;
}

.tab-content {
  background-color: #fff;
  padding: 0.5rem;
}

.nav-tabs-health-exam {
  .nav.nav-tabs {
    border: none !important;

    .nav-item {
      position: relative;
      background: #ecedf2;

      &:not(:last-child) {
        border-right: 1px solid #ccced3;
      }

      &:last-child {
        border-top-right-radius: 4px;
      }

      &.active {
        border-right: none;
        box-shadow: 0 -2px 9px #e9e9eb;
        margin-top: -10px;
        padding-top: 6px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        background: #fff;
        margin-left: -1px;
        z-index: 1;

        &:before,
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          height: 10px;
          width: 20px;
        }

        &:after {
          right: -20px;
          border-radius: 0 0 0 10px;
          -moz-border-radius: 0 0 0 10px;
          -webkit-border-radius: 0 0 0 10px;
          -webkit-box-shadow: -10px 0 0 0 #fff;
          box-shadow: -10px 0 0 0 #fff;
        }

        &:before {
          left: -20px;
          border-radius: 0 0 10px 0;
          -moz-border-radius: 0 0 10px 0;
          -webkit-border-radius: 0 0 10px 0;
          -webkit-box-shadow: 10px 0 0 0 #fff;
          box-shadow: 10px 0 0 0 #fff;
        }
      }

      .nav-link {
        border: none;

        &:after {
          content: "";
          background: var(--main-button-color);
          height: 14px;
          position: absolute;
          width: 2px;
          left: 10px;
          transition: all 250ms ease 0s;
          transform: scale(0);
          border-radius: 10px;
          top: 15px;
        }

        &.active {
          color: var(--main-button-color);
          background-color: #fff;

          &:after {
            transform: scale(1);
          }

          span {
            color: var(--main-button-color);
            font-weight: bold;
          }
        }

        span {
          color: #5f6269;
        }
      }
    }
  }
}

.simplebar-content {
  height: 100%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(109, 106, 106, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.autocomplete-input {
  &::after {
    content: "\f002";
    font-family: "Font Awesome 6 Pro";
    font-size: 1rem;
    top: 2px;
    position: absolute;
    right: 10px;
  }

  typeahead-container {
    top: 38px !important;
    width: 100%;
  }
}

//---------ng select for drug-cascade-control----------

.drug-cascade-select div.ng-value-container div.ng-value {
  background-color: white !important;
  margin-bottom: 2px !important;
}

// .drug-cascade-select div.ng-value-container div.ng-value:has(div.selected-drug-item.not-last-item) {
//   margin-right: 0 !important;
// }

.drug-cascade-select div.selected-drug-item.not-last-item {
  border-radius: 0;
  background-color: inherit;
  padding: 3px 0;
  color: black;
  font-weight: normal;

  // .ng-value-label {
  //   padding-left: 0 !important;
  //   padding-right: 3px !important;
  // }
}

.drug-cascade-select.ng-select-focused .selected-drug-item {
  border-radius: 15px;
  background-color: var(--selected-menu-item-color);
  padding: 3px;
  color: var(--main-button-color);
  font-weight: bold;

  .ng-value-label {
    margin-right: 0.5rem;
  }

  .clear-icon {
    padding: 0.35em;
    color: var(--main-button-color);
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;
    border: 0;
    opacity: 0.5;
    margin-right: 0.3rem;
    cursor: pointer;
  }
}

.drug-cascade-select .selected-drug-item {
  border-radius: 15px;
  background-color: white;
  padding: 3px;
  color: black;
  font-weight: normal;

  .ng-value-label {
    margin-right: 0.5rem;
  }
}

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: none !important;

  .header-title {
    font-weight: bolder;
    display: block;
    margin: 10px auto;
    font-size: 0.8rem;
  }
}

.search-style .ng-arrow-wrapper .ng-arrow,
.search-style-left-icon .ng-arrow-wrapper .ng-arrow {
  display: none !important;
}

.search-style > .ng-select-container:after {
  content: "\f002";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
  top: 5px;
  position: absolute;
  right: 10px;
}

.ng-select-container {
  font-size: var(--bs-body-font-size) !important;
  font-weight: 400 !important;
  border: 1px solid var(--main-border-color) !important;
}

.ng-select .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  min-height: 34.81px !important;
}

.search-style-left-icon > .ng-select-container:after {
  content: "\f002";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
  top: 5px;
  position: absolute;
  left: 10px;
  opacity: 0.4;
}

.search-style-left-icon > .ng-select-container .ng-value-container .ng-value,
.search-style-left-icon
  > .ng-select-container
  .ng-value-container
  .ng-placeholder,
.search-style-left-icon
  > .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  padding-left: 1.5rem !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 34.81px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0px !important;
}

.ng-dropdown-panel {
  border-color: var(--main-border-color) !important;
  background-color: white !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--selected-menu-item-color) !important;
}

.ng-select.is-invalid:not(.ng-select-focused) .ng-select-container {
  border-color: #f46a6a !important;
}

.is-invalid input.p-inputtext {
  border-color: #f46a6a !important;
}

div.section-container {
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: #fff;
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 700;

    &.left-aligned {
      justify-content: left !important;
    }

    .title {
      text-transform: uppercase;
      font-weight: 800;
      margin: 0;
    }
  }
}

div.section-container-parent {
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
}

div.assessment-section-container {
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 1rem;
    background: #fbfbfb;
    margin: 0;
    border-radius: 5px;
  }

  & form {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.horizontal-separator {
  border-bottom: 1px solid var(--main-border-color);
  border-radius: 5px 5px 0 0 !important;
}

.field-section {
  background-color: #fbfbfb;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;

  .field-header {
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
  }

  ul {
    padding: 0.1rem 0 0 1rem;
    margin: 0;
  }
}

.align-left {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auto-height {
  height: auto !important;
}

tr.modified td,
div.modified {
  background-color: var(--selected-menu-item-color) !important;
  font-weight: 700;
  color: var(--main-button-color);
}

//==================stepper=========
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 20px solid #ecf5f4;
  top: 15px;
  width: 50% !important;
  margin-top: 0;
}

.p-steps .p-steps-item:after {
  content: " ";
  border-top: 20px solid #ecf5f4;
  top: 15px;
  margin-top: 0;
  width: 50%;
  left: 50%;
  display: block;
  position: absolute;
}

.p-steps .p-steps-item:first-child::before,
.p-steps .p-steps-item:last-child::after {
  border-radius: 20px !important;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  border: none;
  min-width: 3rem;
  height: 3rem;
  border-radius: 27px;
  background: #fff;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  color: var(--main-button-color) !important;
}

.p-steps.active-0 .p-steps-item:nth-child(-n + 1)::before {
  border-top-color: var(--main-button-color) !important;
}

.p-steps.active-1 .p-steps-item:nth-child(-n + 1)::after,
.p-steps.active-1 .p-steps-item:nth-child(-n + 2)::before,
.p-steps.active-2 .p-steps-item:nth-child(-n + 2)::after,
.p-steps.active-2 .p-steps-item:nth-child(-n + 3)::before,
.p-steps.active-3 .p-steps-item:nth-child(-n + 3)::after,
.p-steps.active-3 .p-steps-item:nth-child(-n + 4)::before {
  border-top-color: var(--main-button-color) !important;
}

li {
  &.p-disabled {
    opacity: 1 !important;
  }

  &.p-highlight.p-steps-current .p-menuitem-link .p-steps-number {
    border: 1px solid #dee2e6 !important;
  }
}

//============side menu and content area=======
#patient-details-left-menu {
  width: 200px;
  border-right: 1px solid var(--main-border-color);

  > ul {
    list-style: none;
    padding: 0;
    margin: 0 1.5rem 0 0;
    font-size: 0.9rem;

    li {
      padding: 0.5rem 1rem 0.5rem 0.75rem;
      cursor: pointer;

      > img {
        width: 20px;
      }

      &.active {
        border-left: 4px solid var(--main-button-color);
        background-color: var(--selected-menu-item-color);
        color: var(--main-button-color);
        font-weight: 700;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        padding-left: 0.5rem;
      }
    }
  }
}

#patient-details-container {
  margin: 0 0 0 1.75rem;
  padding: 0;
  height: calc(100vh - 120px);
  width: calc(100% - 225px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#patient-details-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

//=========================

.p-timeline-event-opposite,
.p-timeline-event-content {
  flex: none !important;
}

.p-timeline .p-timeline-event-marker {
  border: none !important;
  background-color: #96a7b5 !important;
  width: 0.7rem !important;
  height: 0.7rem !important;
}

.p-timeline .p-timeline-event-connector {
  background-color: #96a7b5 !important;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 1px !important;
}

//=================activity history================
.history-container {
  min-height: 70vh;
  max-height: 70vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 2rem;

  & .dots::before {
    content: "";
    position: absolute;
    height: 2rem;
    border-left: 1px dashed #96a7b5;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .dots {
    width: 1rem;
    position: relative;
    height: 1rem;
    border-radius: 50%;
    background-color: #96a7b5 !important;
    display: inline-block;
    min-width: 1rem;
  }
}

.history-container div:last-child > div > span.dots:before {
  content: none !important;
}

.history-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.history-details-container {
  min-height: 60vh;
  max-height: 60vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.history-details-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.is-hidden {
  display: none !important;
}

.patient-search-modal > .modal-content {
  min-height: 340px;
}

.document-template-modal > .modal-content {
  height: 350px;
  .modal-body {
    background-color: #f8f9fa;
    height: 330px;
  }
}

.app-header {
  popover-container {
    margin: 0 !important;
    left: 124px !important;
    top: -46px !important;
    box-shadow: 0 0 10px 0.1rem #556ee621;
    min-width: 300px;
  }

  .popover-body {
    padding: 0 !important;
  }

  .popover-arrow.arrow {
    display: none !important;
  }
}

.week-calendar tr:has(td > span.p-highlight) td > span {
  color: #4338ca;
  background: #eef2ff;
}

.fc-col-header thead tr {
  height: 35px !important;
}

.fc-col-header-cell {
  vertical-align: middle !important;
  font-size: 14px;
}

a.fc-event {
  margin: 0 !important;
  padding: 0 !important;

  cursor: pointer;
  color: white !important;
  text-align: left;

  &.fc-timegrid-event {
    background-color: #4338ca !important;

    &:hover {
      background-color: #4338ca !important;
    }
  }
}

.fc .fc-timegrid-slot {
  height: 40px !important;
  font-size: 14px;
}

.event-container {
  padding: 5px;
  height: 100%;
}

.unconfirmed-appts-counter {
  color: #ffc107 !important;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;

  & i {
    margin-left: 5px;
    font-size: 14px;
  }
}

#appointmentCalendar {
  h2.fc-toolbar-title {
    color: #333 !important;
    font-size: 20px !important;
    display: inline-block;
    font-weight: 700;
    vertical-align: middle;
  }

  button.fc-button {
    background-color: #513dca !important;
    color: #fff !important;
    border: 0px !important;
    padding: 5px 10px !important;
    margin-right: 5px;
  }

  .procedure-multi-color-event {
    background: linear-gradient(
      to right,
      #c5cef8 0%,
      /* Section 1: Red/Orange */ #c5cef8 33%,
      /* Section 1 ends at 33% */ #272c63 33%,
      /* Section 2: Light Blue starts */ #272c63 66%,
      /* Section 2 ends at 66% */ #8bdac3 66%,
      /* Section 3: Green starts */ #8bdac3 100% /* Section 3 ends at 100% */
    );
    border: 2px solid #4338ca;
    border-radius: 0;
    height: 60px;
    border-top: none;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .unconfirmed-events {
    background-color: #f8f1dd !important;
    height: 70px;
    border-top: none;
    border-bottom: none;
    color: #947625 !important;

    & > span {
      display: inline-block;
      left: 0px;
      max-width: 100%;
      overflow: hidden;
      right: 0px;
      vertical-align: top;
      position: sticky;
      padding: 25px 0 0 10%;
    }
  }
}

.p-datepicker .p-datepicker-today span:not(.p-highlight) {
  background-color: transparent;
  color: inherit !important;
}

#scheduleFullCalendar {
  a.fc-event.fc-timegrid-event {
    background-color: white !important;
    text-align: center;
  }

  .scheduled-event-title {
    color: #fff;
    font-size: 10px;
    display: block;
    padding: 0px 5px;
    font-weight: bold;
    text-transform: capitalize;
    line-height: 1.5;
  }

  .blurry {
    opacity: 0.7 !important;
  }

  .vertical-check-in-event {
    background: repeating-linear-gradient(
      -55deg,
      #d1d8f9,
      #d1d8f9 10px,
      #c5cef8 10px,
      #c5cef8 20px
    );
    border-top: 2px solid #0520b6 !important;
    height: 100%;
  }

  .vertical-procedure-event {
    background: repeating-linear-gradient(
      -55deg,
      #5d6295,
      #5d6295 10px,
      #353b7a 10px,
      #353b7a 20px
    );
    height: 100%;
  }

  .vertical-check-out-event {
    background: repeating-linear-gradient(
      -55deg,
      #a2e1cf,
      #a2e1cf 10px,
      #8bdac3 10px,
      #8bdac3 20px
    );
    border-bottom: 2px solid #0520b6 !important;
    height: 100%;
  }
}

.horizontal-check-in-event {
  background: repeating-linear-gradient(
    -55deg,
    #d1d8f9,
    #d1d8f9 10px,
    #c5cef8 10px,
    #c5cef8 20px
  );
  height: 70px;
  border: none;
  padding: 26px 0;
  text-align: center;
}

.horizontal-procedure-event {
  background: repeating-linear-gradient(
    -55deg,
    #5d6295,
    #5d6295 10px,
    #353b7a 10px,
    #353b7a 20px
  );
  height: 70px;
  border: none;
  padding: 26px 0;
  text-align: center;
}

.horizontal-check-out-event {
  background: repeating-linear-gradient(
    -55deg,
    #a2e1cf,
    #a2e1cf 10px,
    #8bdac3 10px,
    #8bdac3 20px
  );
  height: 70px;
  border: none;
  padding: 26px 0;
  text-align: center;
}

.schedule-calendar {
  padding: 0 !important;

  .p-datepicker .p-datepicker-header {
    padding: 0 !important;
    border-bottom: 0px !important;
    margin-bottom: -1rem !important;
  }

  .p-datepicker table td {
    padding: 0 !important;
  }
}

.fc .fc-day-today {
  background-color: transparent !important;
}

.fc-scrollgrid-section-body {
  .fc-datagrid-cell-frame {
    height: 70px !important;
  }

  .fc-timeline-lane-frame {
    height: 70px !important;
  }

  .fc-timeline-event {
    min-height: 70px !important;
  }
}

.offcanvas {
  box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.3);
}

.full-screen-modal {
  width: 100%;
  height: 100%;
  margin: 0;
}

.full-screen-modal .modal-content {
  width: 100vw;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.child-modal .modal-content {
  box-shadow: 0 1rem 5rem rgb(159 159 159 / 80%) !important;
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: auto;
}

.referral-section-container {
  padding: 1rem;
  margin: 0.5rem 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebeaee;
  border-radius: 8px;
  opacity: 1;
}

file-upload.lab-test-upload-container {
  outline: none;
  height: 90px;
  min-height: auto;
  padding: 0;
  margin: 0 0 5px 0;
  background: #f6f8fb;
  border: 1px dashed #513dca;
  border-radius: 10px;

  @media all and (max-width: 1500px) {
    width: 100%;
  }

  .fa-file {
    color: #513dca !important;
    background-color: white !important;
  }
  .upload-list {
    height: 90px;
  }
  &.has-files.list-visible {
    border: none;
    background: none;
    border-radius: 0;
  }
}

.max-len-with-y-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 100px);
}

.small-select {
  width: 120px !important;
}

@media only screen and (min-width: 1200px) {
  .referral-modal.modal-xl {
    max-width: 1540px !important;
  }
}

.schedule-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem;

  &__calendar {
    max-width: 260px;
    width: 100%;

    & p-calendar {
      display: grid;
    }
  }

  table.schedule-time {
    width: 100%;

    td {
      background: transparent;
    }
  }

  select.form-control-border-bottom,
  input.form-control-border-bottom {
    border: 0;
    border-bottom: 2px solid #c2c6d1;
    outline: none;
    height: 34.81px !important;
  }

  .time-slot-select {
    width: 100%;
    border: 0;
    border-bottom: 2px solid #c2c6d1;
    outline: none;

    .ng-select-container {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      background: none !important;
    }

    .ng-arrow-wrapper {
      display: none !important;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

div#referral_completion_progress .progress-bar {
  background-color: #00dab5 !important;
}

.schedule-form {
  .form-control,
  .ng-select-container {
    background-color: #fcfcfc !important;
    border: 1px solid #e2e3e4 !important;
    border-radius: 8px !important;
    margin-bottom: 4px !important;
  }
}

.f-18 {
  font-size: 18px;
}

.f-25 {
  font-size: 25px;
}

.text-light-theme-2 {
  color: #9794bf;
}

.text-light-theme {
  color: #495057;
}

.disabled-panel {
  pointer-events: none;
  opacity: 0.5;
}

.custom-accordion {
  display: block;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 3px 14px #0000000f;
  .panel {
    margin-bottom: 0px;
    background-color: transparent;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.125);
  }

  .accordion-toggle {
    position: relative;
    padding-left: 10px;
    cursor: pointer;
    font-size: 16px;

    &::after {
      content: "";
      background-image: url(/assets/images/custom/acc-plus.png);
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
      background-size: 12px;
      display: inline-block;
      position: absolute;
      top: 40%;
      right: 15px;
    }
  }

  .panel-open {
    .accordion-toggle {
      &::after {
        content: "";
        background-image: url("/assets/images/custom/acc-minus.png");
        background-position: center;
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        background-size: 12px;
        display: inline-block;
        position: absolute;
        top: 40%;
        right: 15px;
      }
    }

    .panel-title {
      background: #eef1fd;
    }
  }

  .panel-heading {
    padding: 0px;
    background-color: white;
  }

  .panel-body {
    &.card-block {
      &.card-body {
        background: white;
      }
    }
  }

  .panel {
    .panel-title {
      .btn {
        color: gray;
      }
    }
  }
  .panel-open {
    .panel-title {
      .btn {
        color: #556ee6;
      }
    }
  }
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropstart .dropdown-toggle-split::after {
  display: none !important;
}

ul#save-confirm-dropdown-split {
  min-width: 140px !important;
  top: -48px !important;
}

.vital-time .p-calendar-timeonly {
  width: 80px;
  font-size: 0.8125rem;
  margin-top: 0.5rem;
}

.form-check-input:checked {
  background-color: #513dca;
  border-color: #513dca;
}

.sedation-start-time .p-calendar.p-calendar-timeonly,
.sedation-end-time .p-calendar.p-calendar-timeonly {
  width: 100%;
  & > input {
    padding: 0.5rem;
  }
}

table.administer-meds,
table.access-site,
table.vitals,
table.act,
table.cpt,
table.rx {
  tr {
    height: 47px;
    th,
    td {
      white-space: nowrap;
      background-color: #ffffff;
    }
  }
}

.missing-items-swal-container {
  > .swal2-popup.swal2-modal {
    width: 500px;
  }
  .swal2-html-container {
    padding: 1rem;
  }
  ul.missing-items-container {
    list-style: none;
    text-align: left;
    padding: 0;
    font-size: 0.8125rem;
  }
}

.operator-note-container {
  .modal-body {
    min-height: 95vh;
    background-color: #f8f9fa;
    overflow: auto;
  }

  .operative-notes-doc-container {
    justify-content: center;
    margin-top: 50px;
    .editor-main-container {
      max-height: calc(100vh - 150px);
      overflow: scroll;
      .card {
        width: 768px;
        margin-bottom: 0 !important;
        background: transparent;
        margin: 0 auto;
        .tox-tinymce {
          border: 1px solid #ced4da !important;
          position: initial !important;
          min-height: 6056px !important;
          margin-bottom: 15px !important;
          max-height: calc(100vh - 165px);
        }
      }
    }
    .tox-menubar {
      position: fixed;
      top: 40px;
      left: 38px;
    }
    .tox-toolbar {
      position: fixed;
      left: 0;
      width: 100%;
      top: 70px;
    }

    .tox-tbtn__select-label {
      color: #495057;
    }

    .tox .tox-mbtn {
      height: 22px !important;
    }
  }
}

.document-generator-container {
  .tox-tinymce {
    border: 1px solid #ced4da !important;
    position: initial !important;
    margin-bottom: 15px !important;
    max-height: calc(100vh - 165px);
  }
}
